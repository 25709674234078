// Style sheet for style rules meant to be applied across both projects (I.E web and mobile), and components.  So theming, button colors, etc.

// Get rid of a last pass box in form fields that otherwise shows up even when ignore lastpass is set.
div.css-1obar3y {
  display: none!important;
}

div[data-lastpass-icon-root="true"] {
  display: none!important;
}


// BUTTON STYLING (ALSO NOT SURE WHY "button" is highlighted. Might be because button is an element name it CS code does not like it when element names are used in a class name.

.button-blue {
  background-color: #428bca !important;//#007BFF;
   font-weight: 400;
   color: white !important; // this overides some material design autoapplied styling
   //height: 40px;
   border-radius: 4px;
   border: 1px solid #3535b3 !important;
   //padding: 6px 12px;
   //line-height: 1.42857143em;
   height: 38px;
   margin-left: 0px !important; //this overides a margin-left: 8px styling that I could not find. It only applies to .button-blue. Weird.
   cursor: pointer;
}

.button-blue:hover {
 background-color: #226cac;
 border: 1px solid #1d1d94 !important
}


.button-blue-stroked {
 font-weight: 400;
 color: black;
 height: 38px;
}

.button-green {
   background-color: #5CB85C !important;
   font-weight: 400;
   color: white !important;
   height: 38px;
   border: 1px solid #2c752c !important;
   border-radius: 4px;
   cursor: pointer;
}

.button-green:hover {
background-color: #419c41;
border: 1px solid #1e5e1e !important;
}

.button-light-blue {
   background-color: #5BC0DE !important;
   font-weight: 400;
   color: white !important;
   height: 38px;
   border: 1px solid #2390b1 !important;
}

.button-light-blue:hover {
background-color: #42a7c5;
border: 1px solid #187a97 !important;
}

.button-yellow {
    background-color: #e59707 !important;//#F0AD4E;
    font-weight: 400;
   color: white !important;
   height: 38px;
   border: 1px solid #a76d1d !important;
   border-radius: 4px;
}

.button-yellow:hover {
background-color: #d89538;
border: 1px solid #8a5710;
}

.button-red {
   background-color: #D9534F !important;
   font-weight: 400;
   color: white !important;
   height: 38px;
   border: 1px solid #752c2c !important;
   border-radius: 4px;
   cursor: pointer;
}

.button-red:hover {
background-color: #ca423e;
border: 1px solid #692424 !important;
}

.button-gray { //overides white text color as white text on gray background doesn't look good and is hard to read.
   background-color: #DADADA !important;
   font-weight: 400;
   color: black !important;
   height: 38px;
   border: 1px solid #adadad !important;
   border-radius: 4px;
}

button.button-gray:hover { //overides white text color as white text on gray background doesn't look good and is hard to read.
background-color: #c7c7c7;
border: 1px solid #8b8b8b !important;
border-radius: 4px;
}


.button-white { //White button is used for nuetral button like exit or cancel. Used ot use button-gray but it looks too much like a disabled button
background-color: white;
font-weight: 400;
color: black;
height: 40px;
border: 1px solid black !important;
}



.button-outline-blue {
    /* background-color: #e59707; */
    color: #1a62a0 !important;
    /* color: white; */
    height: 38px;
    border: 1px solid #0d385d !important;
    font-weight: bold;
}

.button-outline-red {
/* background-color: #e59707; */
color: #df1414 !important;
/* color: white; */
height: 38px;
border: 1px solid #752c2c !important;
font-weight: bold;
}

//Below code overides the fact that background color must be set to important for for all active buttons on the mobile side. But it will also apply to disabled buttons without this on web and mobile unless it is overridden which the below code does
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  background-color: #DCDCDC !important;
  color: #A3A3A3 !important;
  border-color: #A3A3A3 !important;
}


//END BUTTON STYLING SECTION




//Form Corrections

.as-horizontal > .as-split-gutter {
  height: auto !important; //overides height of default 100% and makes vertical gutter/slider visible
}

.as-horizontal > .as-split-area {
  height: auto !important; //overides height of default 100% and makes all columns be the same height (in each individual column control)
  margin-top: 20px; //creates white space between columns on live view (otherwise a form control within a column and between a column is indistinguishable)
}

// Eliminates the base background color of the column splitter on the live view. It is redundent because of the Column spiltter for border, color and width
/*app-formly-column-splitter .as-split-gutter { //
  background-color: transparent !important; //
}*/

//Color for mat radio buttons. This should apply to all radio buttons which is why its in global

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #005691 !important; //Radio Button Styling Color
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #005691 !important; //Radio Button Styling Color
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element { //effects color of mat ripple on radio button
  background-color: #005691 !important;
}


//Color for mat checkboxes. This should apply to all radio buttons which is why its in global

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  //opacity: 0.03 !important;
  background-color: #005691 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #005691 !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #005691 !important;  //effects color of mat ripple on checkbox
}











 //START OF FORM FIELD STYLING SECTION. THIS IS A WORK IN PROGRESS...

 /*.field-time-wrap .mat-form-field-flex .mat-form-field-infix { //Solves issue #10 in github
  padding: .4375em 0 !important;
 }*/ //Cmmented this out as I got rid of the in field labels entirely


 .mat-form-field-appearance-outline .mat-form-field-infix { //This now only applies to form fields that are outlined
  padding: 0px 0px 8.4px 0px !important;
 } //padding changes the padding on the bottom of the form field of the form field

.mat-form-field {// This controls the font size of the text mat form fields
  font-size: 17px;
}


 .form-field-design { //no clue what font size this effects... but I kept it. Try to figure it out later
     font-size: 14px;

 }

 .mat-select-arrow {
   width: 0;
   height: 0;
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 5px solid;
   margin: 0 4px;
}


.mat-form-field-appearance-outline .mat-form-field-outline-gap {
   background-color: white; // Greg Note: I think this only applies if there is a label in the field so its probably useless as there are not floating labels
}

.mat-form-field-outline-start {
   background-color: white; //Greg Note: This controls the background color of the left edge of a form field when appearence is set to outline
}

.mat-form-field-outline-end {
   background-color: white; //Greg Note: This controls the background color of the majority of a form field when appearence is set to outline
}

.mat-focused .mat-form-field-outline-start, .mat-focused .mat-form-field-outline-end, .mat-focused .mat-form-field-outline-gap {
  background-color: rgb(226, 243, 255); //changes bacgkround color of form field when field is selected
 }

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
   opacity: 1!important; // Greg Note: this makes it so there is no "flash" when you initially hover over a form field
}
//END OF FORM FIELD STYLING

//START OF MAT-SELECT STYLING

.mat-select-panel {// Greg Note: These margins make the Dropdown appear underneath and centered to the form field of the drop down. Does not work when you make a selection and click dropdown again though. Come back to this later
 margin-top: 40px;
 margin-left: 30px; //Need to comeback to this as the padding for multiple select is off for the dropdown
}


.mat-form-field-wrapper { //gets rid of padding at bottom of form fields. This padding gives space "hints" and "Errors" helpers but makes the fields much more vertically spaced out than is prefereable for Aestheics
 padding-bottom: 0px;
}

// .nav-width-dd.mat-menu-panel { // NAVIGATION.COMPONENT.HTML makes the menu dropdown have a minimum width. This rule only works in global styles for some reason. The nav-width-dd just makes this rule unique to ONLY this specific menu.
//   min-width: 700px;
//   max-width: 700px;
// }

.mat-dialog-container {
  background: #ececec !important; //adds border color to mat-modal
}



//Changes to get styling back in happy place after angular upgrade

.mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label {
  margin: auto; //makes the text on the gray unscheduled button on the scheduler page centered
  }

.mdc-button {
  letter-spacing: normal !important; //Gets ride of the awful spacing between letters on buttons
}

//Form Field Outline
.mdc-text-field--outlined {
  background-color: white;
}


.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix { //mat form field padding and positioning
  padding: 0px !important;
  padding-top: 7px !important;
  min-height: 36px !important;
}

formly-field-mat-select .mat-mdc-form-field-infix { //added formly-field-mat-select as this styling was targeting other things it should not have. Resolves ticket 1235
  width: 100% !important;
  // This overides canned styling that sets the width to 180px. If this width is set to 180px then
  // if the form field is less than 180 it will stay at 180 and create an x-axis scroll bar which
  // is not what we want. Visuals for how it previously looked and the fix for it can be seen in
  // ticket #1199 in github
}


.mat-mdc-form-field-subscript-wrapper { //controls aesthetics of the form field error messages
  bottom: 5px;
  height: 5px;
}



/* form-field-outline-custom.css */

//mat-form-field border
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgb(224,224,224);
}

//mat-form-field border on hover
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: black !important;
  border-width: 2px !important;
}


//mat-form-field border on select
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #673ab7;
  background: rgba(16, 130, 212, 0.12);
}


//Check Boxes
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #005691 !important;
  background-color: #005691 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark  {
  color: white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: white !important;
}

.mdc-checkbox__background {
  height: 16px !important;
  width: 16px !important;
}

//End of checkboxes


.mat-accent {
  --mat-option-selected-state-label-text-color: #005691 !important;
}


.mat-mdc-radio-button.mat-accent { //changes aesthetic of radio buttons
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #005691 !important;
  --mdc-radio-selected-hover-icon-color: #005691 !important;
  --mdc-radio-selected-icon-color: #005691 !important;
  --mdc-radio-selected-pressed-icon-color: #005691 !important;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #005691 !important;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}


//Disabled Button Aesthetics
.mat-mdc-button[disabled], .mat-mdc-unelevated-button[disabled], .mat-mdc-raised-button[disabled], .mat-mdc-outlined-button[disabled] { //changes the aesthetics of disabled buttons so they actually look disabled
  background-color: #DCDCDC !important;
  color: #A3A3A3 !important;
  border-color: #A3A3A3 !important;
}

.mat-mdc-button .mdc-button__label, .mat-mdc-unelevated-button .mdc-button__label, .mat-mdc-raised-button .mdc-button__label, .mat-mdc-outlined-button .mdc-button__label {
  white-space: nowrap !important; //makes the text on buttons not break to next line (the button will just get longer now)
}

.mdc-line-ripple { //gets rid of the underline in mat-form-field filled text fields.
  display: none;
}



.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: white !important; //makes the text color white on all tags
font-weight: bold;
}

// Modals

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: #ececec !important;
  padding: 24px;
}

// END OF Changes to get styling back in happy place after angular upgrade


//Give the "fade in" aesthetic to new sections that are added to the worfklow

app-formly-section {
  animation-name: example;
  animation-duration: .35s;
  animation-fill-mode: both;
  transform: scale(.75);
  opacity: 0;
  display: grid;
  //transition: linear;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}

@keyframes example {
  0% {
    transform: scale(.75);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

//End of "fade in" code


.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 0px; //this makes the choice control radio button and checkbox selections actually line up correctly with the label.
  padding-bottom: 0px; //this (and min-height: 0px) reduces white space at the bottom that is not needed on the choice control
  min-height: auto;    //this (and padding-bottom: 0px) reduces white space at the bottom that is not needed on the choice control

}

app-formly-textbox-autosize-triggerable:has(.calculate-icon,.dataset-linked-icon) { //Styling for calculated fields
  display: flex !important; //this correclty aligns the mat icons for designating calculated fields and pricebook references
  pointer-events: auto;
 }


 .mat-mdc-form-field-flex:has(.calculate-icon) > .mdc-notched-outline { //Styling for calculated fields
  background-color: #fbe7c5 !important; //give the background color to calculated fields
  border-radius: 4px !important;
  z-index: 0 !important; //Without this the background color will be above the text of the field and you wont be able to see the text
 }

 .mdc-text-field--outlined:has(.calculate-icon).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:has(.calculate-icon).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:has(.calculate-icon).mdc-text-field--focused .mdc-notched-outline__trailing {
 background: none; //for calculated fields this gets rid of the blue color that is present when a form field is active
 //(the blue will mix with the background color we are using to denote calculated fields and it looks bad)
 border-color: #66460f !important;
}

