.commute {
    background-color: gray;
    display: flex;
    position: absolute;
  
    justify-content: center;
    align-items: center;
  }

  .b-sch-clock {
    display: none;
  }

  .striped {
    background : repeating-linear-gradient(-55deg, rgba(230,230,230,0.3), rgba(230,230,230,0.3) 10px, rgba(240,240,240,0.3) 5px, rgba(240,240,240,0.3) 20px);
}

.b-grid-subgrid .scheduledOff {
  background: repeating-linear-gradient(-55deg, rgb(225,225,225), rgb(225,225,225) 10px, rgb(254,254,254) 5px, rgb(254,254,254) 20px);
  //opacity: 0.15;
}
  
  .b-grid-subgrid .scheduledOff.non-standard-schedule {
    background: repeating-linear-gradient(-55deg, rgb(239, 224, 224),  rgb(239, 224, 224) 10px, rgb(254,254,254) 5px, rgb(254,254,254) 20px);
    //opacity: 0.15;
  }
  
  
  .b-grid-subgrid .scheduledWork {
    // background-color: rgb(255,255,255);
    background:rgba(245, 245, 245,.1)
  }
  
  .b-grid-subgrid .scheduledWork.non-standard-schedule {
    background:rgba(121, 146, 98, 0.1)
  }

  .b-sch-dirty-new { //controls styling for notes written on the scheduler when a resource's schedule is changed
    color: black;
    opacity: 1;
    overflow-wrap: anywhere;
    font-weight: bold;
  }

  .site-visit {
    display: flex;
    position: absolute;
    background-color: inherit;
    flex: 1 1 auto; //new
    // justify-content: center;
    // align-items: flex-start;
    flex-direction: column;
  }

  .b-sch-vertical .b-sch-event {
    padding-top: 0px; //this removes top padding from the text for all events on the scheduler (aka all site visits). This way every 15 minute increment can fit one line of text
   padding-left: 0em; //changes left padding for event text and makes the commute take up entire width of the event
   margin-bottom: 1px; //adds 2px margin to the bottom of each event so they there is a more clear delineation between each event
  }