/**
 * @author Saki
 * @date 2019-10-06 21:04:31
 * @Last Modified by: Saki
 * @Last Modified time: 2019-10-06 21:07:02
 *
 * You can add global styles to this file, and also import other style files
 */
// @import "../../bryntum-scheduler-3.1.5/scheduler-3.1.5/build/scheduler.material.css";


@import "../../bryntum-scheduler-3.1.5/build/scheduler.material.css";
@import '~firebaseui/dist/firebaseui.css';
@import "../../common/src/bryntum/bryntum-common.scss";
@import "../../common/src/util/service-vanguard-common.scss";

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.
 */
.mat-slider-thumb-label {
    transform: rotate(45deg) !important;
    border-radius: 50% 50% 0 !important;
  }

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.
 */
.mat-slider-thumb {
    transform: scale(0) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.
 */
.mat-slider-thumb-label-text {
    opacity: 1 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.
 */
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb, .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: lightgrey !important;

}

.errored-event {
  background: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 10px, rgba(190, 190, 190, 0.2) 10px, rgba(190, 190, 190, 0.2) 20px) rgba(255, 255, 255, 0);
  color: inherit;
}

.start-time-tooltip {
  color: cadetblue;
}
.arrival-window-tooltip {
  color: #DADADA;
}

 .info {
     flex-direction : column;
 }

 .desc {
     font-size   : .8em;
     font-weight : 300;
 }

 html, body {
     height : 100%;
 }

 body {
     margin    : .1px; //changed to .1px. it was previously 0 but this caused page break control in form builder to not work. So it is set to .1px which is effectively 0
     font-size : 14px;
     background-color: #fbfbfb;
 }

 body .customer-view {
  background-color: white !important;
}

 // .mat-form-field-wrapper { //gets rid of padding at bottom of form fields
 //     padding-bottom: 0px;
 // }

 // bry-scheduler > div {
 //     flex : 1;
 //     opacity : .8;
 // }

 .dumb-component {background-color: coral;}

 .b-unassigned-class.b-drag-proxy {
  background    : green;
  color         : #fff;
  border-radius : 3px;
  box-shadow    : 0 1px 1px 0 rgba(0, 0, 0, .25);
  align-items   : flex-start;
  padding-left  : .5em;

  &.b-drag-invalid {
      background : red;
  }
}

.b-aborting.b-unassigned-class {
  transition     : transform 0.3s !important;

  // https://app.assembla.com/spaces/bryntum/tickets/7168-drag-drop-cleanup-code-on-abort-is-interfering-with-animation/details#
  background     : red;
  position       : absolute !important;
  z-index        : 10000;
  pointer-events : none;
  opacity        : 0.8;
  box-sizing     : border-box;
}

 bry-scheduler {
     flex : 1;
 }

     .container {
         display: flex;
         flex-direction: column;
         //flex: 1 commenting out flex: 1 removes the space at the top of the mobile app that takes up about half of the screen
     }

     .container bry-scheduler {
         flex: 1;
     }

     bry-scheduler .b-highlighting .b-sch-event {
         opacity : .25;
     }

     bry-scheduler .b-highlighting .b-sch-event.b-match {
         opacity    : 1;
         box-shadow : 0 0 8px red;
     }

     .b-sch-event.drag-commute {
      opacity: .4;
    }

    bry-scheduler .b-guided-scheduling .b-sch-event {
        opacity: .45;
    }

    bry-scheduler .b-guided-scheduling .b-sch-event.b-prospective-event {
      opacity    : 1;
      font-weight: bold;
  }

    bry-scheduler .b-lock-order.b-sch-event {
      opacity    : .55;
  }

    bry-scheduler .b-lock-start.b-sch-event {
      opacity    : .55;
  }


     .my-custom-template
     {
         font-size : 12px;
         border: 1px solid #D2D2D2; //sections of each individual schedule in header
         align-items: flex-start;
         width:120%; //stretches individual day header full length
         box-sizing: content-box;
     }

     .my-custom-template.day-end
     {
     border-right: 4px solid red;
     }


 table.schedule-header {
    // border: 4px solid blue;
    // display: flex; this centers text which is what we DO NOT want
     justify-content: center;
     width: 100%;
     font-size : 16px; //gives padding to top of table
     flex-grow: 1;
     border-collapse: collapse; //positions column header better
     table-layout:fixed;
     white-space: nowrap; //this makes it so the resource name and the date in the header do not bleed into the border
     overflow: hidden; //this makes it so the resource name and the date in the header do not bleed into the border
     text-overflow: clip; //this makes it so the resource name and the date in the header do not bleed into the border
 } //added by Greg

.b-grid-headers { //
        border-right: 5px solid #ebebeb;
 }

.td {
    width: 100%;
}

 svg.expand {
     width: 100%; //this coupled with "width: 120%" in .my-custom-template makes everything resize correctly
 }

 rect.expand {
     width: 100%;
 }

 html, body { height: 100%; }
 body { margin: .1px; font-family: Arial, Roboto, "Helvetica Neue", sans-serif; } //changed margin to .1px. it was previously 0 but this caused page break control in form builder to not work. So it is set to .1px which is effectively 0

 .b-resourceheader-cell {// there is another .b-resourceheader-cell class in another file, this one overeides anything in that one
     padding: 0; //makes border of header align perfectly with the lines on the calendar
     box-sizing: content-box; //fills gap between individual headers
     //border-top: 4px solid red;
     height: 80%; //adjusts vertical height of individual headers
     font-size: 12px;
     color: black;
     font-weight: 400;
     display: relative;
     //height: 80px !important; commented out to accomodate the new commute summation tool, not really sure why it exisited in the first place
 }


   .nav-width-dd.mat-mdc-menu-panel { // NAVIGATION.COMPONENT.HTML makes the menu dropdown have a minimum width. This rule only works in global styles for some reason. The nav-width-dd just makes this rule unique to ONLY this specific menu.
     min-width: 620px !important;
     max-width: 700px !important;
   }

   @import '@angular/material/theming';

 @mixin mat-ripple-theme($theme) {
   .mat-ripple-element {
     background-color: green;
   }
 }






mat-hint {
max-height: 100%;
  position: absolute;
  overflow: auto;
  margin-top: 13px;
  height: 30px;
  display: inline-block;
  color: red;
  width: 280px;
}




 //THE BELOW SHOULD ALWAYS BE COMMENTED OUT, DONT UNCOMMENT IT.

 /* The below will change the padding on all form fields. Some padding must be there if you are using tips or error messages. .form-align should be replaced with whatever unique class you want the field to apply to
 .form-align .mat-form-field-wrapper {
     padding-bottom: 0px;
 }
 */

/*.tabs-section .mat-tab-label { //this gives the labels in tabs more height and width. For some reason you must target the parent and .mat-tab-label, If you just try to target .mat.tag.label the height will not work
  width: 25%; //can't use vw hear, must be percent. if you use vw the tab width will be slightly off due to an inherent wonkiness in material angular tabs
   height: 10vh;
   border: 2px solid blue;
   margin: 30px 50px;
  font-size: 24px;
   border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0,0,0,.3);
  padding: 0px;
  color: blue;
}
*/

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-form-field-disabled .mat-form-field-outline-start {
        background-color: rgb(238, 238, 238); //Greg Note: This controls the background color of the left edge of a form field when appearence is set to outline AND when the field is disabled
    }

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-form-field-disabled .mat-form-field-outline-end {
        background-color: rgb(238, 238, 238); //Greg Note: This controls the background color of the majority of a form field when appearence is set to outline when the field is disabled
    }

.mat-expansion-indicator {
    pointer-events: auto;
      }


.specificity .mat-expansion-panel-body { //this is to make the fields in the expansion panel drop down line up with everything. Located in the address-dropdown component.
    padding: 0px;
    //background-color: rgb(251,251,251);
    }

.specificity.mat-expansion-panel-header {
    //background-color: rgb(251,251,251);
    height: auto !important;
}


.specificity.mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.specificity .mat-expansion-panel-header-description, .specificity .mat-expansion-panel-header-title {
    margin-right: 0px;
}

.specificity .mat-drawer-backdrop.mat-drawer-shown {
    background-color: #fbfbfb;
}


.specificity .mat-drawer:not(.mat-drawer-side) {
    box-shadow: none;
     }

     //the below code changes the size of the mat expansion panel toggle button. I included it hear in case it needs to be changed later. The new customer billing address field will have to be expanded if the toggle is made bigger
 /*.mat-expansion-indicator::after {
        height: 10x;
        width: 10px;
        border-width: 0 5px 5px 0 !important;
    }*/


/*this positions the toggle chevron in the mat expansion panel. The specificity2 ensure this styling only applies to the job-types-page (its the only page that has specificity2) The right selector allows the entire panel to stretch the width of the page but keeps the toggle next to the form field
Also the width in the mat-form-field in the mat-panel-title is set to 38% so it can expand and contract with the toggle spacing*/
   .specificity2 .mat-expansion-indicator {
    right: 60%;
    position: relative;
    padding: 10px; //the padding make the taggle clickable area large
  }


  .b-sch-header-row-0 { //this removes the date which was located to the left of the vertical time axis. schedulerview.component.scss
    display: none;
}

.schedule-grid {
  display: grid;
  grid-template-rows: auto;
  grid-auto-columns: auto;
  grid-gap: 5px;
}



  //Mat-Dialog Stuff
.cdk-global-overlay-wrapper .cdk-overlay-pane {// controls the width of the modal when double clicking site visit on scheduler. This applies t ALL Modals
    width: auto !important; //changed this to auto so modals can take up all the width they need
    max-height: 90vh;
  }

  .cdk-overlay-pane:has(app-load-form) { 
    min-height: 90vh; // stops "choose workflow category" modal from resizing when text is entered into the filter
   }

  // .mat-dialog-container {
  //     background-color: #ececec;
  // }
//Mat-Dialog Stuff

  .b-grid-header-scroller {// This makes the header short so there isn't a bunch of useless white space
    height: 100px; //changed to accomodate the new commute duration summation row
 }

 //Horizontal Mat-Tab Styling

 .mat-mdc-tab-header { //--- tabs.section.component.scss
    border-bottom: none; // makes the tabs and body of the tabs meld together by getting rid of border on bottom of mat tab section
    background-color: white;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.last-tab-extension .mat-tab-label { //--- tabs.section.component.scss
    opacity: 1 !important; //This makes the mat tab labels not have a faded look... which looks horrible
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white; //background color to the tabs
    border-bottom: 1px solid black;
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.last-tab-extension .mat-tab-label-active { //--- tabs.section.component.scss
    background-color: rgb(245, 245, 245) !important; //gives background color to the tab that is currently selected
    border-bottom: 1px solid rgb(245, 245, 245);
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.last-tab-extension .mat-tab-label:last-child { //This sets the last of the mat tab to the below settings. It took FOREVER to figure this out (like 4 hours).
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100% !important;
  pointer-events: none !important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar, .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar{
    background: yellow;
    height: 10px;
    display: none;
  }
  //End Horizontal Mat-Tab Styling




    .site-visit-cont .arrival .mat-mdc-form-field {  //---event-details.component.scss
        width: 140px; //controls width of the arrival window <app-time-field>
    }// Got rid of padding-left: 30px to position the time picker fields better

    .arrival .mat-mdc-form-field {  //---employee.component.scss
      width: 140px; //controls width of the arrival window <app-time-field>
  }// Got rid of padding-left: 30px to position the time picker fields better


    .site-visit-cont .mat-mdc-form-field {  //---event-details.component.scss
        width: 120px; //controls width of the arrival window <app-time-field>
        //font-size: 12px //controls the size fo the entire drop down in the event pop up modal
    }

    /*.mat-select-panel { //---event-details.component.scss
        min-width: 120px !important; // Controls the width of the MD drop down select panel (eg the time window selector on the scheduler site visit pop up modal)
        max-width: 120px !important; // Controls the width of the MD drop down select panel (eg the time window selector on the scheduler site visit pop up modal)

    }
*/ // Cannot figure out how to target specific elements for overlay components. The above targets the time picker in the site visit modal but also targets other drop downs that I don't want it to targert

    /*.sv-notes .mat-input-element {
        grid-column: 2/5;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }*/

   /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
   .sv-notes .mat-form-field-flex .mat-form-field-infix { //---event-details.component.scss
        min-height: 60px; //controles the starting heigh for the site visit notes field on the scheduler event modal pop up
    }

    .site-visit-cont .mat-elevation-z8 { //---event-details.component.scss
        box-shadow: none; // Removes pre set box shadows
        border-top: 5px solid #ececec; // Adds border to top of line item section for aesthetic purposes
    }

    .site-visit-cont .line-items table { //---event-details.component.scss
        width: 100% !important; // Maximizes the width of the line item table in the event pop up modal (it will default to 85% otherwise)
    }

    .mat-drawer.mat-drawer-end {
      border-top: 5px solid #ebebeb;
    }

    .b-gridbase.b-columnresize.b-sort {
      border-top: 5px solid #ebebeb;
    }

// Vertical Mat-Tab Styling

    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
    .vertical-tab-label .mat-tab-labels {
      display: flex;
      flex-direction: column!important;
    }

    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
    .vertical-tab-label .mat-tab-label { //--- tabs.section.component.scss
      opacity: 1 !important; //This makes the mat tab labels not have a faded look... which looks horrible
      background-color: rgb(106, 132, 155); //background color to the tabs
      border-bottom: 1px solid lightgray;
      border-left: 1px solid lightgray;
      color: white;
      //white-space: normal !important; //allows text in mat tab labels to wrap if necessary
      height: fit-content; //allows text in mat tab labels to wrap if necessary
      min-height: 48px; //allows text in mat tab labels to wrap if necessary
      //max-width: 170px;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
  .sub-settings-tabs .mat-tab-label { //--- tabs.section.component.scss
    height: fit-content; //allows text in mat tab labels to wrap if necessary
    min-height: 48px; //allows text in mat tab labels to wrap if necessary
    max-width: 170px;
}

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
  .vertical-tab-label .mat-tab-label .mat-tab-label-content {
    white-space: normal; //allows text in mat tab labels to wrap if necessary
  }

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
  .vertical-tab-label .mat-tab-label-active { //--- tabs.section.component.scss
      background-color: rgb(245, 245, 245) !important; //gives background color to the tab that is currently selected
       border-bottom: 1px solid rgb(245, 245, 245);
      color: black;

  }

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
  .last-tab-extension .mat-tab-label:last-child { //This sets the last of the mat tab to the below settings. It took FOREVER to figure this out (like 4 hours).
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100% !important;
    pointer-events: none !important;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
  .vertical-tab-label .mat-tab-list {
    background-color: rgb(106, 132, 155);
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.sub-settings-tabs.job-type-holder .mat-tab-label:first-child {
  pointer-events: none;
  background-color: rgb(245, 245, 245);
  //padding: 50px 30px;
  height: 100px;
  width: 170px;
  //margin: 50px 30px;
}

/*.sub-settings-tabs.job-type-holder .mat-tab-label-active:first-child {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid black;



  //margin: 50px 30px;
}*/

.mat-expansion-panel  {
  background-color: rgba(0,0,0,0);
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.sub-settings-tabs .mat-mdc-tab-body .mat-tab-body-content {
  overflow-y: auto; //overides the overflow-y: none in Material Design. This is needed in case vertical tab content stretches below the page
  position: fixed; //this makes the details section stay put when the rest of the page is scrolled along the y axis
  //height: 90%; //makes the details section the correct heigh (otherwise things can get cut off if there is overflow on y axis)
  height: calc(100% - 65px);
  border-top: 1px solid black;
  border-right: 1px solid black;
  background-color: rgb(245, 245, 245);
  border-bottom: 1px solid black;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.sub-settings-tabs .mat-tab-label { //--- tabs.section.component.scss
  opacity: 1 !important; //This makes the mat tab labels not have a faded look... which looks horrible
  border-top-left-radius: none;
  border-top-right-radius: none;
  background-color: rgb(245, 245, 245); //background color to the tabs
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-right: 1px solid black;
  color: black;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.sub-settings-tabs .mat-tab-label-active { //--- tabs.section.component.scss
  background-color: rgb(245, 245, 245) !important; //gives background color to the tab that is currently selected
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid rgb(245, 245, 245);
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.sub-settings-tabs .mat-tab-label:last-child { //--- tabs.section.component.scss
  border-top: none;
  border-left: none;
  border-right: 1px solid black;
  //height: 100% !important;
  pointer-events: none !important;
  flex: 1 1 auto;
}


/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.vertical-tab-label.sub-settings-tabs .mat-tab-labels {
  height: 100%;
}

/*.sub-settings-tabs.job-type-holder .mat-tab-body:first-child .mat-tab-body-content {
  border: none;
  width: 10px;
}*/

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.
 */
.primary-radio-positioning .mat-radio-label {
  justify-content: center; //centers horizontally the primary customer radio button on the add job board
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  overflow-wrap: anywhere;
}


/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
 */
.mat-form-field-appearance-outline .mat-form-field-flex {
  margin-top: -.4em !important; //this slightly moves all the form fields up a little bit so they are better alligned. This is most noticeable in the global search field in the navigation section.
}


.short-field {
  width: 100px;
}

.medium-field {
  width: 280px;
}

.long-field {
  width: 380px;
}

// .mat-mdc-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-mdc-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-mdc-outlined-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
//   background-color: #DCDCDC; //the was added to overide Greg's custom buttons. When a button was disabled it would not appear as disabled as Greg's custom button were overriding the disabled css. This give the disabled css more specificity
//   color: #A3A3A3 !important;
//   border-color: #A3A3A3 !important;
// }

// .mat-mdc-menu-item[disabled], .mat-menu-item[disabled]::after, .mat-menu-item[disabled] .mat-icon-no-color { //this styling targets mat menu items when they are disabled. Specifically this was made for the "Unassigned" button on the Add Site Visit button which is on the site visit pop up modal on scheduler
//   background-color: #DCDCDC !important;
//   color: #A3A3A3 !important;
//   border-color: #A3A3A3 !important;
// }

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
 */
.mat-checkbox-inner-container {// makes the fill collor of all checkboxes white
  background-color: white;
}


.tooltip {
  position: relative;
  display: inline-block;
  width: 100%;
  //border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #fff;
  border-radius: 4px;
  margin: 14px;
  max-width: 250px;
  padding-left: 8px;
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1000000;
  position: absolute;
  background: rgba(97,97,97,.9);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  transform-origin: left center;
  transform: translate(-90px,10px);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
 */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #64bd63;
}

//Styling for toggle button

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
 */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
  margin-top: 5px; //adjusts circle to be centered vertically
  margin-left: 2px; //adjusts circle so spacing is even on both sides
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
 */
.mat-slide-toggle-bar {
  width: 40px !important; //makes toggle taller
  height: 24px !important; /// makes toggle wider
  border-radius: 20px !important;
  margin-bottom: 7px !important; // centers the toggle button as it became uncentered due to custom styling
}

/*TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
 */
.mat-slide-toggle-bar, .mat-slide-toggle-thumb {
  margin-top: 5px;
  margin-left: 2px;
}

//End Styling for toggle button


pwa-camera-modal-instance.hydrated{
  z-index: 100000!important;
  //Sometimes this causes words on ion-fab buttons to be eliminated..... we need to see if this is the case when we have icons instead of words.
  //If so, we probably want to switch styling to the formly picture control, and manually mutate class assocaited w/ pwa-camera-modal-instance when
  //we desire to force to front.
  //If this isn't included, (on PWA version at least) the photo picker is hidden behind the display of images.
}


//the below code is for the sign in page
.firebaseui-info-bar-message {
  visibility: hidden;
}

.firebaseui-info-bar-message:after {
  content: 'No Password Set. Please click the "Reset Password" link below. You will receive an email to reset your password';
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 10px;
  padding-top: 10px;
}

.firebaseui-card-header {
  padding: 80px 24px 0px 24px;
}

// .firebaseui-title {
//   padding: 50px 24px 0px 24px;
// }

.firebaseui-id-page-password-recovery .firebaseui-id-submit {
  //visibility: hidden;
  color: #3f51b5;
  width: 200px;
}


.firebaseui-id-page-password-recovery .firebaseui-id-submit:after {
  content: 'Reset Password';
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  width: 200px;
}

//end of code for the sign in page

.cdk-overlay-pane.mat-datepicker-popup {// this positions the date picker calendar more closely to the actual widget on the scheduler page. I cannot figure out how to target a specific cdk overlay directly in the component scss file so I have to do it globally. Simple Greg.
  top: 180px !important;
  left: 200px !important;
}

.tooltip-format {
  white-space: pre-line !important;
  font-size: 12px;
}

mat-icon.disappearing-tooltips{
  margin-top: 8px;
  margin-left: 10px;
  color: #a6a6a6;
  //display: none;
}

.b-sch-vertical .b-sch-event {
  padding-top: 0px; //this removes top padding from the text for all events on the scheduler (aka all site visits). This way every 15 minute increment can fit one line of text
 padding-left: 0em; //changes left padding for event text and makes the commute take up entire width of the event
 margin-bottom: 1px; //adds 2px margin to the bottom of each event so they there is a more clear delineation between each event
}

@media print {
  .do-not-print-this {
    display: none;
  }
}

.double-search-bar-height {
  max-height: 500px !important; //this gives more height to the drop down search results on the sitewide search bar. This must be in global css. It does not work in the component scss file even using ngdeep
}

//Below code eliminates the faded tab color when clicking away from an active tab.
/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.
 */
.mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
.mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
 //  background-color: #FCE500;
 //  font-weight: 700;
 //  color: black;
  opacity: 1;
  }


@media print{
  .donotprint{display:none !important;}
}


tr.mat-mdc-header-row, tr.mat-mdc-footer-row {
  height: 40px !important;
}

tr.mat-mdc-row:not(.example-detail-row) {
  height: 40px !important;
}



.mat-mdc-row:nth-child(even){ //gives darker color to alternating rows
  background-color: #F1F1F1;
}

@media print {
  .mat-mdc-row:nth-child(even){ //gives darker color to alternating rows
    background-color: white;
  }
}

.footer-cust {
  background-color: rgb(248, 248, 248);
  padding: 10px;
  border-top: rgb(220, 220, 220) 2px solid;
}

// Web Only
.new-address-results-width { //Stretches background color to entire width for "New Address Results" on the add jobs page for Address Search
  width: 100%;
}


// .mat-mdc-dialog-container .mdc-dialog__surface {
//   background-color: #ececec !important;
// }



.mdc-text-field--disabled {
  background-color: rgb(238,238,238,.5);
}

app-form-builder .mdc-text-field--disabled {
  background-color: white;
}

html { //changes ripple color from yellow to blue when mat-checkbox is pressed
    --mdc-checkbox-selected-focus-state-layer-color: #005691;
    --mdc-checkbox-selected-pressed-state-layer-color: #005691;
}


.new-address-results-width .mdc-list-item__primary-text { //this makes the "New Address Results header opaque and stretches its width to end of container. this only targets the file customer-search.component.scss. For some reason :host ::ng-deep does not work for this styling when placed in the proper css file
  opacity: 1 !important;
  width: 100%;
}